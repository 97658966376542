<template>
  <div>
    <!-- TODO: remove posted by...? -->
    <top-story :story="topStory"></top-story>

    <div class="column-row column-row-3" style="margin-bottom: 80px; height: 184px;">
      <div class="column column-half first">
        <div class="column-top">
          <h1><router-link to="/community/news-network/s/318012">In-game events</router-link></h1>
        </div>
        <div class="column-content">
          <router-link to="/community/news-network/s/318012" class="rounded">
            <img style="width: 314px; height: 181px;" src="@/assets/community-content/in-game-events.png" alt="In-game events" class="rounded">
          </router-link>
        </div>
      </div>

      <div class="column column-half last">
        <div class="column-top">
          <h1><router-link to="/nexus-hq">Nexus HQ</router-link></h1>
        </div>
        <div class="column-content">
          <router-link to="/nexus-hq" class="rounded">
            <img src="@/assets/community-content/nexus-hq.jpg" alt="Nexus HQ" class="rounded">
          </router-link>
        </div>
      </div>
    </div>

    <div class="column-row column-row-3" style="margin-bottom: 80px; height: 150px;">
      <div class="column column-large first last">
        <div class="column-top">
          <h1>Featured Challenge</h1>
        </div>
        <div class="column-content">
          <community-featured-challenge></community-featured-challenge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CommunityFeaturedChallenge from '@/components/CommunityFeaturedChallenge.vue';
  import TopStory from '@/components/news-network/TopStory.vue';
  import { STORIES } from '@/stories/index.ts';

  export default {
    computed: {
      topStory() {
        return STORIES[343109];
      }
    },
    components: {
      CommunityFeaturedChallenge,
      TopStory,
    }
  }
</script>
