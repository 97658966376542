<template>
  <div id="CommunityFeaturedChallenge">
    <div id="CommunityFeaturedChallengeImage">
      <router-link to="/community/creation-lab/challenge/dc0d16e0-480f-4572-839a-7ebf985b235f" class="rounded">
        <img class="rounded" style="width: 200px; height: 150px;" src="@/assets/challenges/create-a-pet.jpg">
      </router-link>
    </div>
    <div class="column-round" id="CommunityFeaturedChallengeDescription">
      <b><i></i></b><i><b></b></i><em></em>
      <div class="column-round-body">
        <h2><router-link to="/community/creation-lab/challenge/dc0d16e0-480f-4572-839a-7ebf985b235f">
          Create a Pet! (13 and older)
        </router-link></h2>
        <p>
          We have a special contest this month – one lucky builder will have his or her unique pet added to LEGO Universe!
        </p>
        <router-link class="linkArrow" to="/community/creation-lab/challenge/dc0d16e0-480f-4572-839a-7ebf985b235f">View More Creations</router-link>
      </div>
    </div>

    <div class="column-round" id="CommunityFeaturedChallengeCreations">
      <b><i></i></b><i><b></b></i><em></em>
      <div class="column-round-body">
        <div class="ChallengeLogo ChallengeTypeOpen"></div>
        <div class="hr"></div>
        <h3>91</h3>
        <p>creations</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  #CommunityFeaturedChallenge {
    height: 150px;
    overflow: hidden;
  }

  #CommunityFeaturedChallengeImage {
    float: left;
  }

  #CommunityFeaturedChallengeDescription {
    float: left;
    height: 100%;
    width: 347px;
    margin: 0 15px;
  }

  #CommunityFeaturedChallengeCreations {
    float: left;
    height: 100%;
    width: 100px;
  }

  #CommunityFeaturedChallengeCreations .ChallengeLogo {
    margin-left: 8px;
  }

  #CommunityFeaturedChallengeCreations h3 {
    text-align: center;
    margin-top: 8px;
  }

  #CommunityFeaturedChallengeCreations p {
      text-align: center;
  }
</style>
